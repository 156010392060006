<template>
    <div class="main-container"  dir="ltr">
        <div class="row" dir="rtl">
            <div class="col-xl-6 col-md-6">
                <div class="my-account-content2" dir="rtl" style="background: linear-gradient(0deg,#004d4d 0,#03acac );"> 
                    <div class="header-profile-login">
                        <div class="row justify-content-between">
                            <div class="col-8 col-sm-8 col-lg-8 col-xl-8">
                                <h6 class="text-center" style="color: #ffffff;font-size: 20px;">تسجيل دخول</h6>
                            </div>
                            <div class="col-4 col-sm-4 col-lg-4 col-xl-4" style="padding-right: 0;">
                                <img style="width:130px;float:left;" class="logo" src="../../assets/images/logoSvg.svg" />
                            </div>
                        </div>
                        <form id="loginform" @submit.prevent="onLogin()">
                            <p class="login-username">
                                <label for="user_login" style="margin-top: 20px; margin-bottom: 20px; text-align: center;color: #ffffff;">رقم الهاتف</label>
                                <input type="text" v-model="msisdn" class="input" size="15" autocomplete="off" placeholder="09XXXXXXXX" onfocus="this.placeholder = ''" onblur="this.placeholder = 'رقم الهاتف'" maxlength="14">
                            </p>
                            <p class="login-submit">
                                <button class="button button-primary" style="width: 100%; background: linear-gradient(0deg,#077b7b 0,#03acac ); color: rgb(255, 255, 255);" :disabled="isLoading">
                                    <span v-if="isLoading" class="spinner"></span> <!-- Show spinner when loading -->
                                    <span v-else >تسجيل الدخول</span> <!-- Show text when not loading -->
                                </button>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { HTTPDSP } from '@/Api/http-dsp';
import config from '@/Api/config';
import { ref } from 'vue';
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router"
import { useCookie } from 'vue-cookie-next'
export default {
name:'AppLogin',
  mounted() {
    if (this.$cookie.isCookieAvailable("msisdn") && this.$cookie.isCookieAvailable("status")) {
        this.$router.push({ path: "/" })
    }
  },


    setup() {
        const toast = useToast();
        const router = useRouter();
        const cookie = useCookie();

        const msisdn = ref([]);
        const isLoading = ref(false);

        const onLogin = async () => {
            
            if (msisdn.value != "") {
                //Remove non-numeric characters
                let phoneNumber = msisdn.value.replace(/\D/g, '');
                // Define the regex pattern for Sudan Zain numbers
                const zainRegex = /^(?:\+2499|002499|2499|09|9)(0|1|6)\d{7}$/;
                
                // Test the input against the regex
                if (zainRegex.test(phoneNumber)) {

                    let pNumber = '';
                    if (phoneNumber.substring(0, 1) === "0" && phoneNumber.length === 10) {
                        pNumber = phoneNumber.slice(1);
                    
                    } else if (phoneNumber.substring(0, 1) === "9" && phoneNumber.length === 9) {
                        pNumber = phoneNumber.slice();
                    
                    } else if (phoneNumber.substring(0, 1) === "2" && phoneNumber.length === 12) {
                        pNumber = phoneNumber.slice(3);

                    } else if (phoneNumber.substring(0, 1) === "+" && phoneNumber.length === 13) {
                        pNumber = phoneNumber.slice(4);

                    } else if (phoneNumber.substring(0, 1) === "0" && phoneNumber.length === 14) {
                        pNumber = phoneNumber.slice(5);
                    }                   
                    
                    isLoading.value = true

                    try {
                        

                        await HTTPDSP.get(`DSPCheckLogin.php?msisdn=249${pNumber}`, {
                            headers: {
                               'Authorization': config.authHeader,
                            },
                        }).then((res) => {
                            if (res.data.status == 1 && res.data.remming_minutes > 0) {
                                cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                                cookie.setCookie('msisdn', res.data.msisdn, { expire: 60 * res.data.remming_minutes, })
                                cookie.setCookie('minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes,})
                                toast.success("تم تسجيل دخولك استمتع بتجربة فريدة");
                                setTimeout(() => router.push({ path: "/" }), 2500);
                            } else if (res.data.status == 1 && res.data.remming_minutes < 0) {
                                cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                                cookie.setCookie('msisdn', res.data.msisdn, { expire: 60 * res.data.remming_minutes, })
                                cookie.setCookie('minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes,})
                                toast.success("تم تسجيل دخولك استمتع بتجربة فريدة");
                                setTimeout(() => router.push({ path: "/" }), 2500);
                            } else if (res.data.status == 0) {
                                toast.warning("عفوا انت لست مشترك في هذة المنصة", { timeout: 2000, });
                            setTimeout(() => { window.open("https://dsplp.sd.zain.com/?p="+res.data.product_code, "_self"); }, 2500);
                              
                            } else {
                                toast.info("عفوا انت لست مشترك في الخدمة ");
                                setTimeout(() => { window.open("https://dsplp.sd.zain.com/?p="+res.data.product_code, "_self"); }, 2500);
                            }
                        }).catch((error) => {
                           console.log(error);
                        }).finally(() => {
                            isLoading.value = false
                        });
                    } catch (error) {
                        console.log(error);
                    } 

                } else {
                    
                    // If invalid, show error and clear the formatted number
                    toast.info("رقم الهاتف ليس زين");
                }

            } else {
                toast.warning("حقل رقم الهاتف فارغ");
            }
    }
     return {
        onLogin,
        msisdn,
        isLoading
     }
  },
}
</script>

<style scoped>
/* Style for the button */
.loading-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Spinner style */
.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #028A8A;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}
span {
    display: table;
    margin: 0 auto;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>